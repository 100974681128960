import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Partners from '../components/Partners';
import ModalProducts from '../components/ModalProducts';

import undraw_security_o890 from '../img/undraw_security_o890.svg';
import undraw_building_blocks_n0nc from '../img/undraw_building_blocks_n0nc.svg';


const About = () => ( <Layout>
    <div className="skipScroll">
      <Helmet
        title={'What is temtum? | TEM Cryptocurrency | Temporal'}
        meta={[
          {
            name: 'description',
            content: 'What is temtum? A feeless and instant payment cryptocurrency solving the inherent problems with blockchain currencies like Bitcoin.'
          }
        ]}
      />
      <div className="hd-space"/>
      <div className="page">
        <section className="nopadX skipScroll bg-blue-2 text-white combative">
          <div className="blueblue video-overlay"/>
          <div className="wow4 video-overlay"/>
          <div className="py-5 my-5" data-aos="fade-up" data-aos-duration="1400">
            <div className="container">
              <div className="row text-center">
                <div className="col-9 mx-auto">
                  <p className="display-1 font-weight-normal  pb-4">
                    Combative collectivism.
                  </p>
                  <p className="display-4 pb-4">
                    We believe in a decentralised financial future where banks are irrelevant and the people hold the power. We will fight the status quo to make this happen.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pageIntroX text-whiteX about-introX py-5 storyX ">
          <div className="container my-5">
            <div className="row">
              <div className="col-md-8 mx-auto">
                <h2 className="display-4 pb-4 font-weight-normal text-center" data-aos="fade-in" data-aos-duration="400" data-aos-delay="0">
                  We exist to continually define the boundaries of the blockchain and evolve its capability with our Temporal technology.
                </h2>
                <p className="" data-aos="fade-in" data-aos-duration="400" data-aos-delay="0">For cryptocurrency, blockchain needs to take the next step in its evolution and this how temtum will do it. temtum can help secure a truly distributed, decentralised and democratic future for finance where transactions are secure, traceable, transparent using the quantum attack resistant architecture that our experts are world leaders in.
                </p>
                <p className="" data-aos="fade-in" data-aos-duration="400" data-aos-delay="0">This is the future that does not destroy the environment and this is the future that will be here soon. Designed by us, the technology has been built and tested and the implementation mapped out on how this will apply to the real world. <Link to="/whitepaper">This paper</Link> will show you how.
                </p>
              </div>
            </div>
          </div>
          <div className="pb-5" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
            <div className="container">
              <div className="row text-center">
                <div className="col-md-8 mx-auto">
                  <div className="row text-center">
                    <div className="col">
                      <div className="">
                      <span className="timer count-title">
                        <span className="counter" data-counterup-time="1000" data-counterup-delay="200" data-counterup-beginat="0">
                          5
                        </span>
                      </span>
                        <p className="count-text">Academic years of research</p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="">
                      <span className="timer count-title">
                        <span className="counter" data-counterup-time="1000" data-counterup-delay="400" data-counterup-beginat="0">
                          12
                        </span>
                      </span>
                        <p className="count-text ">Published research papers</p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="">
                      <span className="timer count-title">
                        <span className="counter" data-counterup-time="1000" data-counterup-delay="600" data-counterup-beginat="0">
                          3
                        </span>
                      </span>
                        <p className="count-text ">Cryptography experts</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="skipScroll bg-light-grey">
          <div className="" data-aos="fade-up" data-aos-duration="200">
            <div className="container">
              <div className="row text-centerX">
                <div className="col-9 mx-auto text-center">
                  <h1 className="display-2 font-weight-bold headlineX redX pb-2X">
                    Our Focus
                  </h1>

                  <div className="containerX plain-cards text-dark">
                    <div className="row">
                      <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                          <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"/>
                          <li data-target="#carouselExampleIndicators" data-slide-to="1"/>
                          <li data-target="#carouselExampleIndicators" data-slide-to="2"/>
                        </ol>
                        <div className="carousel-inner">
                          <div className="carousel-item active ">
                            <div className="card-body">
                              <p className=" text-dark lead">
                                Create a future that is held in the hands of anyone with the most basic forms of technology, bringing access to vital financial services and empowering the ‘unbanked’.
                              </p>
                            </div>
                          </div>
                          <div className="carousel-item ">
                            <div className="cardX card-body">
                              <p className=" text-dark lead">
                                Create a future for everyone, wherever they live in the world, in which the benefits of blockchain cryptocurrency are available for all.
                              </p>
                            </div>
                          </div>
                          <div className="carousel-item ">
                            <div className="cardX card-body">
                              <p className="text-dark lead">
                                A future that does not destroy the environment.
                              </p>
                            </div>
                          </div>
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                          <span className="carousel-control-prev-icon" aria-hidden="true"/>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                          <span className="carousel-control-next-icon" aria-hidden="true"/>
                          <span className="sr-only">Next</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className=" skipScroll bg-blue-3 text-white" id="infrastructure">
          <div className="wow2 video-overlay"/>

          <div className="" data-aos="fade-up" data-aos-duration="400">
            <div className="container mb-5">
              <div className="row text-center">
                <div className="col-10 mx-auto">
                  <p className="display-1 font-weight-normalX  mb-4">
                    We don’t just speculate; we dream big and make it happen. We’ve already made it a reality.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="text-white skipScroll" id="products" data-aos="fade-up" data-aos-duration="400">
            <div className="container text-dark dark-cards">
              <div className="row">
                <div className="col-lg-3 mx-auto mb-4">
                  <div className="card box borderX bg-platform-mobileX">
                    <h3 className="display-4 font-weight-bolder">
                      Mobile Applications
                    </h3>
                    <p className="mb-4">Wallets on both Android and Apple.</p>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#Mobile">
                      View <i className="fas fa-long-arrow-alt-right ml-2"/>
                    </div>

                  </div>
                </div>
                <div className="col-lg-3 mx-auto mb-4">
                  <div className="card box borderX bg-platform-mobileX">
                    <h3 className="display-4 font-weight-bolder">
                      Keyboard Payments
                    </h3>
                    <p className="mb-4">
                      Send and receive TEM via apps like Whatsapp and Telegram.
                    </p>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#Keyboard">
                      View <i className="fas fa-long-arrow-alt-right ml-2"/>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 mx-auto mb-4">
                  <div className="card box borderX bg-platform-mobileX">
                    <h3 className="display-4 font-weight-bolder">Web Wallet</h3>
                    <p className="mb-4">
                      Create an account and manage your temtum funds.
                    </p>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#Wallet">
                      View <i className="fas fa-long-arrow-alt-right ml-2"/>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mx-auto mb-4">
                  <div className="card box borderX bg-platform-mobileX">
                    <h3 className="display-4 font-weight-bolder">
                      SMS Transfers
                    </h3>
                    <p className="mb-4">
                      The SMS system allows for instant transfers.
                    </p>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#Sms">
                      View <i className="fas fa-long-arrow-alt-right ml-2"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="nopadX skipScroll bg-blue-3 text-white  ttl">
          <div className="py-5" data-aos="fade-up" data-aos-duration="400">
            <div className="container">
              <div className="row text-center">
                <div className="col-8 mx-auto">
                  <h2 className="display-1 font-weight-normal pb-4">
                    You think now is good?
                    <br/>
                    Wait until you see the future.
                  </h2>
                  <p>
                    We have a first class team working alongside our experts, continually pushing the boundaries of blockchain and evolving its capabilities with our Temporal technology.
                  </p>
                  <Link className="btn btn-outline-light mt-4" to="/team">
                    Meet the Team
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="partners" className="bg-white border-bottom skipScroll">
          <div className="container">
            <div className="row" data-aos="fade-in" data-aos-duration="400">
              <div className="col col-6 col-sm-6 col-md-6  mx-auto  text-center">
                <h3 className="display-3 font-weight-bold mb-4">Partners</h3>
              </div>
            </div>
          </div>

          <Partners/>

        </section>
      </div>
    </div>
    <div>
      <ModalProducts
        key={'Mobile'}
        id="Mobile"
        img={undraw_building_blocks_n0nc}
        title="Mobile Applications"
        description={
          <React.Fragment>
            <p>We’ve developed our temtum wallet into ios and Android applications to allow for seamless use of TEM across all devices. This allows for your TEM to be used from anywhere and isn’t limited to a single device or location like many cryptocurrency wallets.</p>
          </React.Fragment>
        } />

      <ModalProducts
        key="Keyboard"
        id="Keyboard"
        img={undraw_security_o890}
        title="Keyboard Payments"
        description={
          <React.Fragment>
            <p>
              This unique smartphone keyboard allows the user to access the benefits of the temtum application in a seamless way and can be used from any mobile messaging application, including Facebook, Whatsapp and Telegram, without the need for specialist currencies per application. It means that users can simply click on a custom temtum button and, without opening the temtum app, temtum coins (TEM) can be sent from one account to another.
            </p>
            <p>
              The keyboard is installed as a customised keyboard service as part of the temtum application. This enables sharing of the core functionality and the stored user credentials using the most secure standards for mobile apps. The user can access the temtum transfer functionality very simply, from any application.
            </p>
            <p>
              Both these features – the temtum application and the temtum keyboard – use the same temtum API capabilities and apply the same security standards. This design ensures data integrity between transactions either for the temtum mobile application and the temtum keyboard.
            </p>
          </React.Fragment>
        } />

      <ModalProducts
        key="Wallet"
        id="Wallet"
        img={undraw_security_o890}
        title="Web Wallet"
        description={
          <React.Fragment>
            <p>
              The temtum wallet works in the same way as a bank account in two fundamental ways – it is both a repository for currencies and a mechanism for completing transactions. Each wallet uses temtum technology to make transactions with other wallets on the network. But it is also different to a conventional bank account in other ways, such as:
            </p>
            <ul>
              <li>The wallet can be identified with only a username and/or wallet-address.</li>
              <li>Transaction speed: transactions will be confirmed within 12 seconds instead of waiting for days.</li>
              <li>Every transaction is confirmed by a decentralized network.</li>
              <li>temtum transactions have been tested using the web application, mobile applications, drone, watch, raspberry Pi and even a car.</li>
            </ul>
            <p>
              The temtum wallet is a cryptocurrency wallet where users can manage their TEM. We have combined all the necessary elements including security, simplicity, and easy access. The temtum website has full details of all the applications and examples of how to use the features of each application.
            </p>
          </React.Fragment>
        } />

      <ModalProducts
        key="Sms"
        id="Sms"
        img={undraw_security_o890}
        title="Transfers"
        description={
          <React.Fragment>
            <p>
              The SMS system does not require users to download an app, and there are no requirements for accounts or passwords. If the user has a device that can send an SMS text message, then they are able to use temtum. Similarly, the recipient needs neither an app nor a wallet to receive funds. This makes it suitable for non-smartphones and is targeted at  countries with a high penetration rate of such devices, the use of which is driven by unpredictable electrical power and intermittent, or less widely available, high speed internet.
            </p>
            <p>
              The SMS system allows for instant transfers. Transactions are immediately settled directly on the Temporal Blockchain and funds are never held by a third party.
            </p>
          </React.Fragment>
        } />
    </div>
  </Layout>
)
export default About;
