import React from 'react';

const ModalProducts = ( props ) => (
  <div className="teampopupX modal fade" id={props.id} tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
    <div className="modal-dialog modal-dialog-zoom modal-md">
      <div className="modal-content">
        <div className="modal-body p-lg-5">
          <img src={props.img} alt=""/>
          <h4 className="display-4 font-weight-bold mt-3 mb-4">{props.title}</h4>
          <div>{props.description}</div>
          <hr className="my-5"/>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div> );

export default ModalProducts;
